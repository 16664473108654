'use client'

import React from 'react'
import { Divider, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { Icon, IconProps } from '@/components/common/Icon'
import { useCurrentOrganization } from '@/core/hooks/useCurrentOrganization'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'
import { useCurrentUserOrganizationsData } from '@/core/hooks/useCurrentUserOrganizationsData'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import { Styles } from '@/styles/styles'
import { OrganizationsList } from './OrganizationsList'
import styles from './ProfileMenuButton.module.scss'

const getItems = ({
  logout,
}: {
  logout(): void
}): { title: string; iconName: IconProps['name']; divider?: boolean; onClick?(): void }[] => [
  { title: 'About', iconName: 'InfoIcon', divider: true },
  { title: 'Log Out', iconName: 'LogOutIcon', onClick: logout },
]

export const ProfileMenuButton: React.FC = () => {
  const { logout, loginToOrganization } = useNextAuth()
  const { user } = useCurrentUser()

  const { organization } = useCurrentOrganization()
  const { data: organizationsData } = useCurrentUserOrganizationsData()

  const organizationName = organization?.display_name

  const onOrganizationClick = (org_id: string) => {
    loginToOrganization({ org_id })
  }

  return (
    <Menu isLazy>
      <MenuButton as="div" className={styles.menuButton}>
        <div className="text-xs">{user.name}</div>
        <div>{organizationName}</div>
      </MenuButton>
      <MenuList className={styles.containerMenu} zIndex={Styles.zIndexHeaderMenu}>
        <OrganizationsList
          menuItemClassName={styles.menuItem}
          items={organizationsData?.organizations || []}
          selectedItemId={user.org_id!}
          onItemClick={onOrganizationClick}
        />
        <MenuDivider className={styles.fullDivider} />
        {getItems({ logout }).map((item) => (
          <React.Fragment key={item.title}>
            <MenuItem className={styles.menuItem} onClick={item.onClick}>
              <Icon name={item.iconName} />
              <span>{item.title}</span>
            </MenuItem>
            {item.divider && <Divider />}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  )
}
