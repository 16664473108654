import React from 'react'
import { LazyPageNavigatorPanelView } from './LazyPageNavigatorPanelView'
import styles from './PageNavigatorPanel.module.scss'

interface PageNavigatorPanelProps {
  closePanel(): void
}

export const PageNavigatorPanel: React.FC<PageNavigatorPanelProps> = ({ closePanel }) => {
  return (
    <>
      <div className={styles.container}>
        <LazyPageNavigatorPanelView closePanel={closePanel} />
      </div>
      <div className={styles.backdrop} onClick={closePanel} />
    </>
  )
}
