'use client'

import React, { useEffect } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { Loader } from '@/components/common/Loader'
import { PAGE, PUBLIC_PAGES, type PageHref, getPagesByUserPermissions } from '@/core/constants/pages'
import { useCurrentOrganization } from '@/core/hooks/useCurrentOrganization'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import { getRedirectUrl } from '@/core/utils/url'
import { AUTH0_PATH } from './constants'

const isPublicPath = (path: PageHref | string) => PUBLIC_PAGES.includes(path as PageHref)

export const AuthLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const pathname = usePathname()
  const { isLoggedIn, status } = useNextAuth()
  const { organizationModel, isFetching: isOrgFetching } = useCurrentOrganization()

  const authCheck = async (url: string) => {
    const path = url.split('?')[0]
    const notAuthorized = !isLoggedIn && !isPublicPath(path)

    if (status === 'loading' || isOrgFetching) {
      return
    }

    if (notAuthorized) {
      router.push(AUTH0_PATH.LOGIN)
      return
    }

    if (isLoggedIn) {
      // Skip checking if page available
      if (organizationModel?.isPageAvailable(path)) {
        return
      }

      const pagePermissions = getPagesByUserPermissions({ organization: organizationModel! })
      const allowedPages = (Object.keys(pagePermissions) as PageHref[]).filter((key) => !!(pagePermissions as any)[key])
      const firstValidPath = allowedPages[0]

      const redirectUrl = getRedirectUrl(firstValidPath)
      const isRedirectUrlAllowed = !!allowedPages.filter((page) => redirectUrl?.startsWith(page))[0]
      const redirectPage = isRedirectUrlAllowed ? redirectUrl : firstValidPath

      router.push(redirectPage || '/')
      return
    }
  }

  useEffect(() => {
    authCheck(pathname || '')
  }, [isLoggedIn, status, pathname, organizationModel])

  const isPageAvailable = pathname ? organizationModel?.isPageAvailable(pathname) : isOrgFetching

  if (isOrgFetching || !isPageAvailable) {
    return <Loader size="xl" />
  }

  if (!isLoggedIn && isPublicPath(pathname || '')) {
    return <>{children}</>
  }

  if (isLoggedIn) {
    return <>{children}</>
  }

  return <Loader size="xl" />
}
