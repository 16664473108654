import React from 'react'
import { Loader } from '@/components/common/Loader'
import { lazyComponent } from '@/components/common/utils/lazyImport'

export const LazyPageNavigatorPanelView = lazyComponent(
  () => import(/* webpackChunkName: 'PageNavigatorPanelView' */ './PageNavigatorPanelView'),
  {
    preload: true,
    loading: () => (
      <>
        <Loader />
      </>
    ),
  },
)
